@import "https://use.fontawesome.com/ede1f9233a.css";
@import url(https://fonts.googleapis.com/css?family=Arvo|Hind);

.CrmSiteProfileMutationsEditPerson_tussenvoegsel.element_container, .CrmSiteProfileMutationsEditPerson_partnernaam_tussenvoegsel.element_container{
  display:none !important;
}

button#slick-slide-control00
{
  display: none;
}
div#cookieMsg {
  display:none;
}